import React from 'react'
import { Link } from "react-router-dom";
import * as Img from '../../components/Img'
import Icon from '../../components/Icon';
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const Banifit = () => {
    // useGSAP ( () => { 
    //     gsap

    //   }) ;
    return (
        <>
            <div className='benifit-section p-80'>

                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='oat-box row-3'>
                                <div className="buttons">
                                    <Link to="/" className="btn-primary">
                                        Our Benefits
                                    </Link>
                                </div>
                                <div className='custom-heading ch-medium'>
                                    <span>The benefits</span>
                                    that set us apart
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 offset-lg-2 row-3'>
                            <div className='custom-heading ch-xssmall'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
                            </div>
                            <div className="buttons">
                                <Link to="/get-funded" className="btn-primary">
                                    Get Funded <span><Icon.ArrowrightSvg /></span>
                                </Link>
                            </div>
                        </div>

                    </div>

                    <div className='custom-evaluation-box row-3'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className="evaluation-card  ec-card">
                                    <img src={Img.Boxleft} className='left-s' />
                                    <img src={Img.Boxright} className='right-s' />
                                    <div className="card-header">
                                        <span className="card-index">0.1</span>
                                        <div className="icon-container">
                                            <div className="icon-bg">
                                                <img src={Img.Boxicon} alt="Calendar Icon" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-content row-2">
                                        <h3 className="card-title">Relax</h3>
                                        <h4 className="card-subtitle">No max or min evaluation days</h4>
                                        <p className="card-description">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="evaluation-card ec-card">
                                    <img src={Img.Boxleft} className='left-s' />
                                    <img src={Img.Boxright} className='right-s' />
                                    <div className="card-header">
                                        <span className="card-index">0.2</span>
                                        <div className="icon-container">
                                            <div className="icon-bg">
                                                <img src={Img.Boxicon} alt="Calendar Icon" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-content row-2">
                                        <h3 className="card-title">Relax</h3>
                                        <h4 className="card-subtitle">No max or min evaluation days</h4>
                                        <p className="card-description">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className="evaluation-card ec-card">
                                    <img src={Img.Boxleft} className='left-s' />
                                    <img src={Img.Boxright} className='right-s' />
                                    <div className="card-header">
                                        <span className="card-index">0.3</span>
                                        <div className="icon-container">
                                            <div className="icon-bg">
                                                <img src={Img.Boxicon} alt="Calendar Icon" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-content row-2">
                                        <h3 className="card-title">Relax</h3>
                                        <h4 className="card-subtitle">No max or min evaluation days</h4>
                                        <p className="card-description">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="evaluation-card ec-card">
                                    <img src={Img.Boxleft} className='left-s' />
                                    <img src={Img.Boxright} className='right-s' />
                                    <div className="card-header">
                                        <span className="card-index">0.5</span>
                                        <div className="icon-container">
                                            <div className="icon-bg">
                                                <img src={Img.Boxicon} alt="Calendar Icon" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-content row-2">
                                        <h3 className="card-title">Relax</h3>
                                        <h4 className="card-subtitle">No max or min evaluation days</h4>
                                        <p className="card-description">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="evaluation-card ec-card">
                                    <img src={Img.Boxleft} className='left-s' />
                                    <img src={Img.Boxright} className='right-s' />
                                    <div className="card-header">
                                        <span className="card-index">0.5</span>
                                        <div className="icon-container">
                                            <div className="icon-bg">
                                                <img src={Img.Boxicon} alt="Calendar Icon" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-content row-2">
                                        <h3 className="card-title">Relax</h3>
                                        <h4 className="card-subtitle">No max or min evaluation days</h4>
                                        <p className="card-description">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Banifit