import React from 'react'
import Banner from './Banner'
import Howitwork from './Howitwork'
import Ouradvantages from './Ouradvantages'
import Banifit from './Banifit'
import Mask from './Mask'
import Getstart from './Getstart'
import Pricetable from './Pricetable'
import Certificates from './Certificates'
import Priceindustry from './Priceindustry'
import Trader from './Trader'
import Faq from './Faq'
import Customersupport from './Customersupport'



const Home = () => {
  return (
    <>
      <Banner />
      <Howitwork />
      <Ouradvantages />
      <Banifit />
      <Mask />
      <Getstart />
      <Pricetable />
      <Certificates />
      <Priceindustry />
      <Trader />
      <Faq/>
      <Customersupport/>
    </>
  )
}

export default Home