import React, { useState, useRef } from 'react';
import * as Img from '../../components/Img'

const Accordion = () => {
    const [openIndex, setOpenIndex] = useState(null); // Tracks the currently open accordion
    const refs = useRef([]); // Store refs for each accordion panel

    const data = [
        { title: 'No max or min evaluation days?', content: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna' },
        { title: 'Who can trade here?', content: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna' },
        { title: 'What assets can I trade?', content: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna' },
        { title: 'Are there any withdrawal restrictions?', content: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna' },
        { title: 'No max or min evaluation days?', content: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna' },
    ];

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle the accordion
    };

    return (
        <>
            <div className='accordion-section p-80 custom-bg'>
                <img src={Img.leftscroll} className='left-s' />
                <img src={Img.rightscroll} className='right-s' />
                <div className='container row-3'>
                    <div className='custom-heading ch-medium text-center'>
                        All answer to the most popular questions
                    </div>
                    <div className='custom-heading ch-xssmall text-center'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna <span>aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco aliquip.</span>
                    </div>
                    <div className="accordion">
                        {data.map((item, index) => (
                            <div key={index} className="accordion-item">
                                {/* Accordion Title */}
                                <div className="accordion-title" onClick={() => toggleAccordion(index)}>
                                    <span>{item.title}</span>
                                    <span className="accordion-icon">{openIndex === index ? '-' : '+'}</span>
                                </div>

                                {/* Accordion Content */}
                                <div
                                    className="accordion-content"
                                    ref={(el) => (refs.current[index] = el)} // Save the ref for the current accordion
                                    style={{
                                        maxHeight: openIndex === index ? `${refs.current[index]?.scrollHeight}px` : '0',
                                        transition: 'max-height 0.3s ease-out',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {item.content}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Accordion;
