import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";


//Header section
import ScrollToTop from "./ScrollToTop";
import Loader from "./Loader";
import Header from "../container/Header/Header";

// Pages
import Home from "../container/PageHome/Home";
import Footer from "../container/Footer/Footer";

export const pages = [
  { path: "/", component: Home },
];

const MyRoutes = () => {
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Initialize Locomotive Scroll
    const locoScroll = new LocomotiveScroll({
      el: scrollContainerRef.current,
      smooth: true,
      lerp: 0.1, // Adjust for smoother scrolling
      multiplier: 1.2,
    });

    // Update ScrollTrigger on Locomotive scroll
    locoScroll.on("scroll", ScrollTrigger.update);

    // Set up ScrollTrigger proxy with Locomotive Scroll
    ScrollTrigger.scrollerProxy(scrollContainerRef.current, {
      scrollTop(value) {
        return arguments.length
          ? locoScroll.scrollTo(value, 0, 0)
          : locoScroll.scroll.instance.scroll.y;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      pinType: scrollContainerRef.current.style.transform ? "transform" : "fixed",
    });

    // Refresh ScrollTrigger and Locomotive Scroll
    ScrollTrigger.addEventListener("refresh", () => locoScroll.update());
    ScrollTrigger.refresh();

    return () => {
      locoScroll.destroy();
      ScrollTrigger.removeEventListener("refresh", () => locoScroll.update());
    };
  }, []);

  return (
    <div ref={scrollContainerRef} data-scroll-container>
      <ScrollToTop />
      <Loader />
      <Header />
      <Routes>
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />;
        })}
      </Routes> 
      <Footer />
    </div>
  );
};

function MainRoutes() {
  return (
    <Router>
      <MyRoutes />
    </Router>
  );
}

export default MainRoutes;
