import React from 'react'
import { Link } from "react-router-dom";
import * as Img from '../../components/Img'
import Icon from '../../components/Icon';
import "../../css/header.css";

const Header = () => {
    return (
        
            <header className="navbar">
                <div className='container'>
                <div className="logo">
                    <Link to="/">
                        <img src={Img.logo} alt='logo' />
                    </Link>
                </div>
                <nav className="menu">
                    <Link to="/trading-rules"><span>0.1 /</span> Trading Rules</Link>
                    <Link to="/blog"><span>0.2 /</span> Blog</Link>
                    <Link to="/faq"><span>0.3 /</span> FAQ</Link>
                    <Link to="/contact-us"><span>0.4 /</span> Contact Us</Link>
                </nav>
                <div className="auth-buttons">
                    <Link to="/login" className="btn-login">
                        Login
                    </Link>
                    <Link to="/get-funded" className="button-transparent">
                        Get Funded  <span><Icon.ArrowrightSvg /></span>
                    </Link>
                </div>
                </div>
            </header>
       
    )
}

export default Header