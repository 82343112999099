import React, { useState, useEffect, useRef } from "react";
import Icon from '../../components/Icon';
import gsap from "gsap";

const PriceIndustry = () => {
    const [activeTab, setActiveTab] = useState("Copper");
    const cardsRef = useRef();

    const plans = {
        Copper: [
            {
                capital: "$5,000",
                price: "$25",
                details: {
                    target: "6-8%",
                    drawdown: "10%",
                    dailyDrawdown: "4%",
                    leverage: "1:200 / RCF:1:100",
                    trades: "4",
                    days: "Unlimited",
                    split: "80%-90%",
                    refundFee: "200%",
                    funding: "$10,000",
                },
            },
        ],
        Bronze: [
            {
                capital: "$10,000",
                price: "$50",
                details: {
                    target: "8-10%",
                    drawdown: "12%",
                    dailyDrawdown: "5%",
                    leverage: "1:200 / RCF:1:100",
                    trades: "5",
                    days: "Unlimited",
                    split: "85%-90%",
                    refundFee: "250%",
                    funding: "$20,000",
                },
            },
        ],
        Silver: [
            {
                capital: "$10,000",
                price: "$50",
                details: {
                    target: "8-10%",
                    drawdown: "12%",
                    dailyDrawdown: "5%",
                    leverage: "1:200 / RCF:1:100",
                    trades: "5",
                    days: "Unlimited",
                    split: "85%-90%",
                    refundFee: "250%",
                    funding: "$20,000",
                },
            },
        ],
        Gold: [
            {
                capital: "$10,000",
                price: "$50",
                details: {
                    target: "8-10%",
                    drawdown: "12%",
                    dailyDrawdown: "5%",
                    leverage: "1:200 / RCF:1:100",
                    trades: "5",
                    days: "Unlimited",
                    split: "85%-90%",
                    refundFee: "250%",
                    funding: "$20,000",
                },
            },
        ],
        Diamond: [
            {
                capital: "$10,000",
                price: "$50",
                details: {
                    target: "8-10%",
                    drawdown: "12%",
                    dailyDrawdown: "5%",
                    leverage: "1:200 / RCF:1:100",
                    trades: "5",
                    days: "Unlimited",
                    split: "85%-90%",
                    refundFee: "250%",
                    funding: "$20,000",
                },
            },
        ],
    };

    const switchTab = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        gsap.fromTo(
            cardsRef.current.children,
            { y: 50, opacity: 0 },
            { y: 0, opacity: 1, duration: 0.5, stagger: 0.2 }
        );
    }, [activeTab]);

    return (
        <>
            <div className="priceindustry p-80">
                <div className="container row-3">
                    <div className="custom-heading ch-medium text-center">Best prices in prop industry!</div>
                    <div className="custom-heading ch-xssmall text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
                    <div className="pricing-tabs row-4">
                        <div className="tabs">
                            {Object.keys(plans).map((tab) => (
                                <button
                                    key={tab}
                                    className={`tab ${activeTab === tab ? "active" : ""}`}
                                    onClick={() => switchTab(tab)}
                                >
                                    {tab}
                                </button>           
                            ))}
                        </div>

                        <div ref={cardsRef}>
                            <div className="cards">
                                <div className="evaluation-card ec-card  row-2">
                                    <div className="comparison">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="tl "> Account size</th>
                                                    <th className="price-info">

                                                        <div className="price-was">Virtual capital size</div>
                                                        <div className="price-now">$5,000</div>
                                                        <button className="choose-plan"> Buy for <span>$25</span> <Icon.ArrowrightSvg /></button>

                                                    </th>
                                                    <th className="price-info">
                                                        <div className="price-was">Virtual capital size</div>
                                                        <div className="price-now">$10,000</div>
                                                        <button className="choose-plan"> Buy for <span>$25</span> <Icon.ArrowrightSvg /></button>

                                                    </th>
                                                    <th className="price-info">
                                                        <div className="price-was">Virtual capital size</div>
                                                        <div className="price-now">$20,000</div>
                                                        <button className="choose-plan"> Buy for <span>$25</span> <Icon.ArrowrightSvg /> </button>

                                                    </th>
                                                    <th className="price-info">
                                                        <div className="price-was">Virtual capital size</div>
                                                        <div className="price-now">$30,000</div>
                                                        <button className="choose-plan"> Buy for <span>$25</span> <Icon.ArrowrightSvg /></button>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    {/* <td colspan="4">Seperate business from personal spending</td> */}
                                                </tr>
                                                <tr className="compare-row">
                                                    <td>Profit target</td>
                                                    <td>6-8%</td>
                                                    <td>6-8%</td>
                                                    <td>6-8%</td>
                                                    <td>6-8%</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    {/* <td colspan="4">Estimate tax payments</td> */}
                                                </tr>
                                                <tr>
                                                    <td>Max drawdown</td>
                                                    <td>10%</td>
                                                    <td>10%</td>
                                                    <td>10%</td>
                                                    <td>10%</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    {/* <td colspan="4">Track deductible mileage</td> */}
                                                </tr>
                                                <tr className="compare-row">
                                                    <td>Daily drawdown</td>
                                                    <td>4%</td>
                                                    <td>4%</td>
                                                    <td>4%</td>
                                                    <td>4%</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    {/* <td colspan="4">Download online banking</td> */}
                                                </tr>
                                                <tr>
                                                    <td>Leverage</td>
                                                    <td>Upto 1:200/RCF1:100</td>
                                                    <td>Upto 1:200/RCF1:100</td>
                                                    <td>Upto 1:200/RCF1:100</td>
                                                    <td>Upto 1:200/RCF1:100</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    {/* <td colspan="4">Works on PC, Mac & mobile</td> */}
                                                </tr>
                                                <tr className="compare-row">
                                                    <td>Minimum trades</td>
                                                    <td>4</td>
                                                    <td>4</td>
                                                    <td>4</td>
                                                    <td>4</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    {/* <td colspan="4">Create invoices & estimates</td> */}
                                                </tr>
                                                <tr>
                                                    <td>Maximum days</td>
                                                    <td>Unlimited</td>
                                                    <td>Unlimited</td>
                                                    <td>Unlimited</td>
                                                    <td>Unlimited</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    {/* <td colspan="4">Manage VAT</td> */}
                                                </tr>
                                                <tr className="compare-row">
                                                    <td>Profit split</td>
                                                    <td>80%-90%</td>
                                                    <td>80%-90%</td>
                                                    <td>80%-90%</td>
                                                    <td>80%-90%</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    {/* <td colspan="4">Run payroll</td> */}
                                                </tr>
                                                <tr>
                                                    <td>Refund fee</td>
                                                    <td>200%</td>
                                                    <td>200%</td>
                                                    <td>200%</td>
                                                    <td>200%</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    {/* <td colspan="4">Number of users</td> */}
                                                </tr>
                                                <tr className="compare-row">
                                                    <td>Maximum funding</td>
                                                    <td>$10,000</td>
                                                    <td>$10,000</td>
                                                    <td>$10,000</td>
                                                    <td>$10,000</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PriceIndustry;
