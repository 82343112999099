import React from 'react'
import { Link } from "react-router-dom";
import * as Img from '../../components/Img'
import Icon from '../../components/Icon';
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const Getstart = () => {
    // useGSAP ( () => { 
    //     gsap

    //   }) ;
    return (
        <>
            <div className='getstart-section p-80' style={{
                backgroundImage: `url(${Img.Getstart})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#000',
            }}>
                <div className='container row-3'>
                    <div className='custom-heading ch-medium'>
                        Get started today
                    </div>
                    <div className='custom-heading ch-xssmall'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                    </div>

                    <div className='gss-inner'>
                        <div className="evaluation-card  ec-card">
                            <img src={Img.Dashboardone} alt='dashboardone' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Getstart