import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import * as Img from "../../components/Img";
import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";

const Howitwork = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
    });

    return () => {
      scroll.destroy();
    };
  }, []);

  return (
    <div ref={scrollRef} data-scroll-container className="how-it-work p-80">
      <img
        src={Img.leftscroll}
        className="left-s"
        alt="Left Scroll"
        data-scroll
        data-scroll-direction="horizontal"
        data-scroll-speed="-6"
      />
      <img
        src={Img.rightscroll}
        className="right-s"
        alt="Right Scroll"
        data-scroll
        data-scroll-direction="horizontal"
        data-scroll-speed="6"
      />
      <div className="container">
        <div className="hiw-inner">
          <div className="buttons" >
            <Link to="/" className="btn-primary"  >
              How it Works
            </Link>
          </div>
          <div className="custom-heading ch-medium" >
            Easy way to become a funded trader
          </div>

          <div className="hiw-inner-box">
            <div className="row">
              <div className="col-lg-6">
                <div className="hib-box box-left" data-scroll data-scroll-direction="horizontal" data-scroll-speed="-3">
                  <div className="custom-heading ch-small">Easy way to become a funded trader</div>
                  <div className="custom-heading ch-xssmall">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
                  </div>
                  <img
                    src={Img.Line}
                    alt="line"
                    className="line"
                    data-scroll
                    data-scroll-direction="horizontal"
                    data-scroll-speed="1"
                  />
                  <img
                    src={Img.Glow}
                    alt="glow"
                    className="glow"
                    data-scroll
                    data-scroll-direction="horizontal"
                    data-scroll-speed="2"
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="hib-box box-right" data-scroll data-scroll-direction="horizontal" data-scroll-speed="3">
                  <div className="custom-heading ch-small">Take 1 step Challenge</div>
                  <div className="custom-heading ch-xssmall">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="hib-box box-left" data-scroll data-scroll-direction="horizontal" data-scroll-speed="-2">
                  <div className="custom-heading ch-small">Get verified</div>
                  <div className="custom-heading ch-xssmall">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
                  </div>
                  <img
                    src={Img.Line}
                    alt="line"
                    className="line"
                    data-scroll
                    data-scroll-direction="horizontal"
                    data-scroll-speed="1"
                  />
                  <img
                    src={Img.Glow}
                    alt="glow"
                    className="glow"
                    data-scroll
                    data-scroll-direction="horizontal"
                    data-scroll-speed="2"
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="hib-box box-right" data-scroll data-scroll-direction="horizontal" data-scroll-speed="2">
                  <div className="custom-heading ch-small">Get Funded</div>
                  <div className="custom-heading ch-xssmall">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Howitwork;
