import React from 'react'
import { Link } from "react-router-dom";
import * as Img from '../../components/Img'
import Icon from '../../components/Icon';
import "../../css/footer.css";

const Footer = () => {
    return (
        <>
            <footer className="footer p-100">
                <div className='container'>
                    <div className="footer-top">
                        <div className="footer-logo row-4">
                             <img src={Img.logo} alt='logo' />
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor...
                            </p>
                            <p className='small'>© 2024 All rights reserved</p>
                        </div>
                        <div className="footer-links">
                            <div className="footer-column">
                                <h4>Explore</h4>
                                <ul>
                                    <li><Link to='/'>Affiliate </Link></li>
                                    <li><Link to='/'>How it Works</Link></li>
                                    <li><Link to='/'>Get Funded</Link></li>
                                </ul>
                            </div>
                            <div className="footer-column">
                                <h4>Info</h4>
                                <ul>
                                    <li><Link to='/'>Disclaimer</Link></li>
                                    <li><Link to='/'>Privacy Policy</Link></li>
                                    <li><Link to='/'>Cancellation</Link></li>
                                    <li><Link to='/'>Terms</Link></li>
                                </ul>
                            </div>
                            <div className="footer-column">
                                <h4>Newsletter</h4>
                                <p>Be the first to know about promotions <span>and discount</span></p>
                                <div className="newsletter-input">
                                    <input type="email" placeholder="Enter your email" />
                                    <button><Icon.ArrowrightSvg /></button>
                                </div>
                                <div className="social-icons">
                                    <Link to='/'><i className="fab fa-facebook-f"></i></Link>
                                    <Link to='/'><i className="fab fa-youtube"></i></Link>
                                    <Link to='/'><i className="fab fa-linkedin-in"></i></Link>
                                    <Link to='/'><i className="fab fa-twitter"></i></Link>
                                    <Link to='/'><i className="fab fa-instagram"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="footer-column">
                            <h4>All answers to the most popular</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                ad minim veniam, quis nostrud exercitation ullamco aliquip.
                            </p>
                        </div>
                        <div className="footer-column">
                            <h4>All answers to the most popular</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                ad minim veniam, quis nostrud exercitation ullamco aliquip.
                            </p>
                        </div>
                        <div className="footer-column">
                            <h4>All answers to the most popular</h4>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                ad minim veniam, quis nostrud exercitation ullamco aliquip.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer