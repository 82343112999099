import React from 'react'
import { Link } from "react-router-dom";
import * as Img from '../../components/Img'
import Icon from '../../components/Icon';
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const Customersupport = () => {
    // useGSAP ( () => { 
    //     gsap

    //   }) ;
    return (
        <>
            <div className='customer-support p-100' style={{
                backgroundImage: `url(${Img.Customersupport})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>
                <div className='container'>
                    <div className="community-section">
                        <div className="container row-3">
                            <div className='custom-heading ch-medium text-center'>
                                Join Our
                                <div className="logo-circle">
                                    <div className="logo-icon">
                                        <img src={Img.Comper} alt='comper' />
                                    </div>
                                </div>
                                Community
                            </div>
                            <div className='custom-heading ch-xssmall text-center'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
                            </div>

                            {/* Stats */}
                            <div className="stats">
                                <div className="stat-item">
                                    <h2 className="stat-value">30k+</h2>
                                    <p className="stat-label">Active users</p>
                                </div>
                                <div className="stat-item">
                                    <h2 className="stat-value">10+</h2>
                                    <p className="stat-label">Themed chats</p>
                                </div>
                                <div className="stat-item">
                                    <h2 className="stat-value">Promotions</h2>
                                    <p className="stat-label">Every month</p>
                                </div>
                            </div>

                            {/* Button */}
                            <div className="buttons">
                                <Link to="/get-funded" className="btn-primary">
                                    Join <b>FUNDED FIRM</b> <span><Icon.ArrowrightSvg /></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='lets-talk'>
                    <div className='lt-heading text-center'>
                        Lets Talk Lets Talk
                    </div>
                    <div className='lt-inner'>
                        <img src={Img.letstalk} alt='letstalk' />
                        <div className='lt-inner-bx row-3'>
                            <div className='custom-heading ch-medium text-center'>
                                All answer to the most popular questions
                            </div>
                            <div className='custom-heading ch-xssmall text-center'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
                            </div>
                            {/* Button */}
                            <div className="buttons">
                                <Link to="/get-funded" className="btn-primary">
                                    Join <b>FUNDED FIRM</b> <span><Icon.ArrowrightSvg /></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Customersupport