import React from "react";
import MyRouter from "./routers/index";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";


function App() {
  // useGSAP ( () => { 
  //   gsap

  // }) ;

  return (
    <MyRouter />
  );
}

export default App;