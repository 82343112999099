import React from 'react'
import { Link } from "react-router-dom";
import * as Img from '../../components/Img'
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const Ouradvantages = () => {

    // useGSAP ( () => { 
    //     gsap

    //   }) ;
    return (
        <>
            <div className='ouradvantage p-80' style={{
                backgroundImage: `url(${Img.advantage})`,
                backgroundPosition: '50% 65%',
                backgroundRepeat: 'no-repeat',
            }}>
                <div className='container'>
                    <div className='oat-box row-3'>
                        <div className="buttons">
                            <Link to="/" className="btn-primary">
                                Our Advantages
                            </Link>
                        </div>
                        <div className='custom-heading ch-medium'>
                            <span>How dose <img src={Img.logo} alt='logo' /></span>
                            compare with other companies?
                        </div>
                    </div>


                    <div className="oat-table">
                        <div className="oat-card">
                            {/* Comparison Table */}
                            <div className="oat-grid">
                                {/* Column 1: Left */}
                                <div className="column">
                                    <div className="cc-title"></div>
                                    <div className="cc-card"><span><img src={Img.Rightarrow} alt='rightarrow' /></span> Profit Target</div>
                                    <div className="cc-card"><span><img src={Img.Rightarrow} alt='rightarrow' /></span> Daily Loss Limit</div>
                                    <div className="cc-card"><span><img src={Img.Rightarrow} alt='rightarrow' /></span> Trailing Max Drawdown</div>
                                    <div className="cc-card"><span><img src={Img.Rightarrow} alt='rightarrow' /></span> Activation Fee</div>
                                    <div className="cc-card"><span><img src={Img.Rightarrow} alt='rightarrow' /></span> Scaling Rule</div>
                                    <div className="cc-card"><span><img src={Img.Rightarrow} alt='rightarrow' /></span> Capped Withdrawals</div>
                                    <div className="cc-card"><span><img src={Img.Rightarrow} alt='rightarrow' /></span> Minimum Trading Days</div>
                                </div>

                                {/* Column 2: Middle (Highlighted) */}
                                <div className="column highlighted text-center">

                                    {/* Logo Section */}
                                    <div className="logo-wrapper">
                                        <div className="logo-circle">
                                            <div className="logo-icon">
                                                <img src={Img.Comper} alt='comper' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cc-title">
                                        When you trade <span>with FINDED FIRM</span>
                                    </div>
                                    <div className='cc-center-box'>
                                        <div className='cc-inner-content'>
                                            <div className='cci-box'>
                                                <div className="cc-card">6-8%</div>
                                                <div className="cc-card">-</div>
                                                <div className="cc-card">3-4%</div>
                                                <div className="cc-card">Starter Only</div>
                                                <div className="cc-card">Starter Only</div>
                                                <div className="cc-card">60 days</div>
                                                <div className="cc-card">1 day</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Column 3: Right */}
                                <div className="column text-center">
                                    <div className="cc-title">When you trade<span>with Others</span></div>
                                    <div className="cc-card">$9,000</div>
                                    <div className="cc-card">2%</div>
                                    <div className="cc-card">3-4%</div>
                                    <div className="cc-card">-</div>
                                    <div className="cc-card">-</div>
                                    <div className="cc-card">12 days & 3 payouts</div>
                                    <div className="cc-card">2 days</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Ouradvantages