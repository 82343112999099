import React from 'react'
import { Link } from "react-router-dom";
import * as Img from '../../components/Img'
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Masonry from "react-masonry-css";

const Trader = () => {
    // useGSAP ( () => { 
    //     gsap

    //   }) ;

    const testimonials = [
        {
            id: 1,
            name: "Pranav Raval",
            image: Img.User,
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.",
        },
        {
            id: 2,
            name: "Pranav Raval",
            image: Img.User,
            text: "The dashboard is very intuitive and user-friendly and the support team is very responsive and helpful...",
        },
        {
            id: 3,
            name: "Pranav Raval",
            image: Img.User,
            text: "The path to financial freedom Very good evening, thank you for changing my life before belonging to the FundedFirm family...",
        },
        {
            id: 4,
            name: "Pranav Raval",
            image: Img.User,
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
        },
        {
            id: 5,
            name: "Pranav Raval",
            image: Img.User,
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
        },
        {
            id: 6,
            name: "Pranav Raval",
            image: Img.User,
            text: "The dashboard is very intuitive and user-friendly and the support team is very responsive and helpful...",
        },
        {
            id: 7,
            name: "Pranav Raval",
            image: Img.User,
            text: "The path to financial freedom Very good evening, thank you for changing my life before belonging to the FundedFirm family...",
        },
        {
            id: 8,
            name: "Pranav Raval",
            image: Img.User,
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
        },
    ];
    const breakpointColumnsObj = {
        default: 6,
        1400: 5,
        1100: 3,
        768: 2,
        500: 1,
    };
    return (
        <>
            <div className='traders-section p-80' style={{
                backgroundImage: `url(${Img.Traders})`,
                backgroundPosition: 'bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                position: 'relative', // Ensure this container becomes the stacking context
            }}>
                {/* Add a separate element for the Tradline background */}
                <div
                    className="tradline-background"
                    style={{
                        backgroundImage: `url(${Img.Tradline})`,
                        backgroundPosition: 'bottom left',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        position: 'absolute',
                        top: 0,
                        // left: 0,
                        width: '100%',
                        height: '100%',
                        // zIndex: 9, // Push this background behind the content
                    }}
                ></div>

                <div className='container-fluid row-3'>
                    <div className='custom-heading ch-medium text-center'>
                        Loved by traders <span>worldwide</span>
                    </div>
                    <div className='custom-heading ch-xssmall text-center'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                    </div>


                    <div style={{ padding: "20px" }}>
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {testimonials.map((testimonial, index) => (
                                <div
                                    key={testimonial.id}
                                    className={`card ${index % 2 === 0 ? "odd" : ""} row-1`}
                                >
                                    <div className='ts-inner'>
                                        <img src={testimonial.image} alt={testimonial.name} />
                                        <h3>{testimonial.name}</h3>
                                    </div>
                                    <p>{testimonial.text}</p>
                                </div>
                            ))}
                        </Masonry>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Trader