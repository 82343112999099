import React from 'react'
import { Link } from "react-router-dom";
import * as Img from '../../components/Img'
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const Mask = () => {
     // useGSAP ( () => { 
    //     gsap

    //   }) ;
  return (
    <>
    <div className='mask-section p-80'>
        <div className='container'>
            <img src={Img.Mask} alt='mask' />
        </div>
    </div>
    </>
  )
}

export default Mask