import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import * as Img from '../../components/Img';
import Icon from '../../components/Icon';
import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";

const Banner = () => {
    const videoRef = useRef(null);
    const scrollContainerRef = useRef(null);

    // Initialize Locomotive Scroll

    // Handle the video resizing to cover the full screen
    useEffect(() => {
        const video = videoRef.current;
        const resizeVideo = () => {
            const scale = Math.max(1, Math.min(video.clientWidth / video.videoWidth, video.clientHeight / video.videoHeight));
            video.style.transform = `translate(-50%, -50%) scale(${scale})`;
        };
        resizeVideo();
        window.addEventListener('resize', resizeVideo);
        return () => {
            window.removeEventListener('resize', resizeVideo);
        };
    }, []);

    return (
        <div className="landing-page" ref={scrollContainerRef} data-scroll-container>
            <div className="pattern-bx">
                <div className="gradient-background-bx">
                    <div className="gb-inner">
                        <div className="gb-shape gb-s-2"></div>
                    </div>
                </div>
            </div>

            {/* Background Video with Fade-up and Scale-down Effect */}
            <video
                autoPlay
                loop
                muted
                playsInline
                className="background-video"
                id="background-video"
                ref={videoRef}
                data-scroll
                data-scroll-speed="-2"   // Speed at which the video scrolls
                data-scroll-opacity="0.2" // Starting opacity (before scroll)
                data-scroll-scale="1"     // Initial scale value
                data-scroll-target="#background-video"  // Ensures the video scales properly
                data-scroll-direction="vertical"
            >
                <source src={Img.Banner} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {/* Gradient Shadow Below */}
            {/* Overlay */}
            <div className="overlay"></div>

            {/* Content */}
            <div className="banner-contnet">
                <h1 className="custom-heading">
                    Manage simulated capital <br />
                    up to <span>$600k</span>
                </h1>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed possimus
                    tempora incidunt ut<span>labore et dolore magnam alias.</span>
                </p>
                <div className="buttons">
                    <Link to="/get-funded" className="btn-primary">
                        Get Funded <span><Icon.ArrowrightSvg /></span>
                    </Link>
                    <Link to="/" target="_blank" className="btn-secondary">
                        Join our Discord
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Banner;
