import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import Icon from "../../components/Icon";

const Pricetable = () => {
    const [activeTab, setActiveTab] = useState("$50,000 account");
    const cardsRef = useRef();

    const plans = {
        "$50,000 account": [
            { type: "Starter", price: "$300", details: { target: "$6,000", position: "10 contracts", lossLimit: "None", drawdown: "$3,000", mode: "EOD" } },
            { type: "Expert", price: "$300", details: { target: "$6,000", position: "10 contracts", lossLimit: "None", drawdown: "$3,000", mode: "EOD" } },
        ],
        "$100,000 account": [
            { type: "Starter", price: "$500", details: { target: "$12,000", position: "20 contracts", lossLimit: "None", drawdown: "$6,000", mode: "EOD" } },
            { type: "Expert", price: "$500", details: { target: "$12,000", position: "20 contracts", lossLimit: "None", drawdown: "$6,000", mode: "EOD" } },
        ],
        "$150,000 account": [
            { type: "Starter", price: "$700", details: { target: "$18,000", position: "30 contracts", lossLimit: "None", drawdown: "$9,000", mode: "EOD" } },
            { type: "Expert", price: "$700", details: { target: "$18,000", position: "30 contracts", lossLimit: "None", drawdown: "$9,000", mode: "EOD" } },
        ],
    };

    const switchTab = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        gsap.fromTo(
            cardsRef.current.children,
            { y: 50, opacity: 0 },
            { y: 0, opacity: 1, duration: 0.5, stagger: 0.2 }
        );
    }, [activeTab]);

    return (
        <>
            <div className="pricetable p-80">
                <div className="container row-4">
                    <div className="custom-heading text-center">Choose what’s best for you</div>
                    <div className="pricing-tabs row-4">
                        <div className="tabs">
                            {Object.keys(plans).map((tab) => (
                                <button
                                    key={tab}
                                    className={`tab ${activeTab === tab ? "active" : ""}`}
                                    onClick={() => switchTab(tab)}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>

                        <div className="cards " ref={cardsRef}>
                            {plans[activeTab].map((plan) => (
                                <div className="card " key={plan.type}>
                                    <div className="evaluation-card ec-card row-2">
                                        {/* Title */}
                                        <h2 className="card-title">{plan.type}</h2>
                                        {/* Subtitle */}
                                        <h3 className="card-subtitle">{plan.price}<span>/month</span></h3>
                                        {/* Description */}
                                        <p className="card-description">Simple and powerful</p>
                                        <button className="choose-plan">
                                            Choose {plan.type} Plan <Icon.ArrowrightSvg />
                                        </button>
                                        <div className="details">
                                            {/* Details Title */}
                                            <h4 className="details-title">Get Started Today:</h4>
                                            <p>Profit Target: <span>{plan.details.target}</span></p>
                                            <p>Max Position: <span>{plan.details.position}</span></p>
                                            <p>Daily Loss Limit: <span>{plan.details.lossLimit}</span></p>
                                            <p>Trailing Max Drawdown: <span>{plan.details.drawdown}</span></p>
                                            <p>Drawdown Mode: <span>{plan.details.mode}</span></p>
                                        </div>
                                        <button className="view-all">View All<span><Icon.ChevrondownSvg /></span></button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Pricetable;
