import React from 'react'
import * as Img from '../../components/Img'
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const Certificates = () => {
    // useGSAP ( () => { 
    //     gsap

    //   }) ;
    return (
        <>
            <div className='certificates p-relative p-80 custom-bg'>
                <div className="pattern-bx">
                    <div className="gradient-background-bx">
                        <div className="gb-inner">
                            <div className="gb-shape gb-s-2">
                            </div>
                        </div>
                    </div>
                </div>
                <img src={Img.leftscroll} className='left-s' />
                <img src={Img.rightscroll} className='right-s' />
                <div className='container row-4'>
                    <div className="custom-heading text-center">Payout certificates</div>

                    <div className="evaluation-card ec-card" >
                        <img src={Img.Cirtione} alt='cirtione' />
                    </div>
                </div>

            </div>
        </>
    )
}

export default Certificates